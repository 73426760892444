<template>
		<div class="feature">
			<cms-image
				class="image"
                lazy
				:src="feature.items_image"
				:base="{width: 450, height: 134}"
				:xl="{width: 450, height: 200}"
				:xxl="{width: 450, height: 134}"
			/>
			<base-font class="title" tag="h3" color="secondary" uppercase >{{ feature.items_title }}</base-font>
		</div>
</template>
<script>
export default {
	props: {
		feature: Object
	}
}
</script>
<style lang="scss" scoped>
::v-deep .image {
	.base-image {
		border-radius: .2rem;
		max-width: 100%;
		width: 100%;
		display: block;
	}
}

::v-deep .title {
	font-weight: 600;
	letter-spacing: 0.04em;
	text-align: center;
	margin-top: 1rem;
	line-height: 2.1rem;
	@include media-breakpoint-up(xl) {
        font-size: 2.2rem;
		margin-top: 2rem;
		line-height: 3.1rem;
    }
	@include media-breakpoint-up(xxl) {
        font-size: 2.6rem;
    }
}
</style>
