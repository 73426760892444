<template>
		<section class="section-wrapper" id="why_us" v-animate="'opacity'">
			<base-heading tag="h2" :heading="`${prefix}.section_title`" :subheading="`${prefix}.subtitle`"/>
			<grid-template cols="3">
				<base-feature v-for="(feature, index) in value.items" :key="index" :feature="feature"/>
			</grid-template>
		</section>
</template>

<script>
import BaseHeading from '~/website/front/components/molecules/headings/BaseHeading/BaseHeading.vue'
import BaseFeature from '../../components/molecules/BaseFeature/BaseFeature.vue'
import GridTemplate from '~/website/front/templates/GridTemplate.vue'
export default {
	components: { BaseHeading, BaseFeature, GridTemplate },
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
    padding: 4rem 0;
    @include media-breakpoint-up(xl) {
        padding: 8rem 0;
    }

	::v-deep(.heading-wrapper){
		@include media-breakpoint-up(xl){
			margin-bottom: 6rem;
		}
	}
}

::v-deep(.grid-wrapper) {
	gap: 3rem;
	grid-template-columns: auto;

	@include media-breakpoint-up(md) {
		gap: 3rem;
        grid-template-columns: repeat(2, 1fr);
    }

	@include media-breakpoint-up(lg) {
		gap: 6rem 3rem;
        grid-template-columns: repeat(3, 1fr);
    }
}
</style>
